import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import {
  sections as SectionsAllLanguages,
  sectionsInterface,
} from "./constants";

export default function Navbar({handleSectionSelect, }: { handleSectionSelect: (section: number) => void; }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const language = localStorage.getItem("lang") || "en";
  let sections: sectionsInterface;
  if (language === "fr") {
    sections = SectionsAllLanguages.fr;
  } else if (language === "cn") {
    sections = SectionsAllLanguages.cn;
  } else {
    sections = SectionsAllLanguages.en;
  }

  const handleSectionClick = (section: string) => {
    handleSectionSelect(Object.values(sections).indexOf(section));
  };

  const DrawerList = (
    <Box sx={{ width: 200 }} onClick={toggleDrawer(false)}>
      <List>
        {Object.values(sections).map((section, index) => (
          <ListItem key={index}>
            <ListItemButton onClick={() => handleSectionClick(section)}>
              <ListItemText primary={section} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="Navbar">
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon style={{ color: "black" }} />
      </Button>
      <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
