import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { questionsAndAnswers, qaInterface, sections } from './constants';

const QA = () => {

  const language = localStorage.getItem("lang") || "en";
  let qa: qaInterface[]; let title: string;
  if (language === "fr") {
    qa = questionsAndAnswers.fr;
    title = "Questions et réponses";
  } else if (language === "cn") {
    qa = questionsAndAnswers.cn;
    title = sections.cn.qa;
  } else {
    qa = questionsAndAnswers.en;
    title = "Questions and Answers";
  } 

  return (
    <div className='qa' id='qa'>
        <h3>{title}</h3>
        
        {qa.map((q, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              style={{textAlign:'left'}}
            >
              {q.question}
            </AccordionSummary>
            <AccordionDetails style={{textAlign:'justify'}}>
              <div>
                {q.answer && !q.answer2 && (
                  <>{q.answer}</>
                )}
                {q.answer2 && !q.answer3 && (
                  <>{q.answer}<br/><br/>{q.answer2}</>
                )}
                {q.answer2 && q.answer3 && !q.answer4 && (
                  <>{q.answer}<br/><br/>{q.answer2}<br/><br/>{q.answer3}</>
                )}
                {q.answer2 && q.answer3 && q.answer4 && (
                  <><b>{q.answer}</b><br/>{q.answer2}<br/><br/><b>{q.answer3}</b><br/>{q.answer4}</>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
  );
}

export default QA;