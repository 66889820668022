import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { rsvpPage, rsvpPageInterface } from "./constants";
export interface RSVPData {
  [key: string]: string | number;
  rsvpid: number;
  whosfriend: string;
  where: string;
  firstname: string;
  lastname: string;
  plusone: string;
  plusonefirstname: string;
  plusonelastname: string;
  message: string;
  email1: string;
  hiddenemail1: string;
  email2: string;
  hiddenemail2: string;
  church1: string;
  church2: string;
  venue1: string;
  venue2: string;
  meal1: string;
  meal2: string;
  allergies1: string;
  allergies2: string;
  notes: string;
}

const stages = {
  SEARCH: 0,
  LIST: 1,
  VIEW: 2,
  EDIT: 3,
  CONFIRM_CHURCH: 4,
  CONFIRM_VENUE: 5,
  CONFIRM_MEAL: 6,
  NOTES: 7,
  SEND_EMAIL: 8,
  EMAIL_SENT: 9,
};

const RSVP: React.FC<{ changeSection: (section: number) => void }> = ({ changeSection }) => {
  const dummyData: RSVPData = {
    rsvpid: -1,
    whosfriend: "",
    where: "",
    firstname: "",
    lastname: "",
    plusone: "",
    plusonefirstname: "",
    plusonelastname: "",
    message: "",
    email1: "",
    hiddenemail1: "",
    modifiedemail1: "",
    email2: "",
    hiddenemail2: "",
    modifiedemail2: "",
    church1: "",
    church2: "",
    venue1: "",
    venue2: "",
    meal1: "",
    meal2: "",
    allergies1: "",
    allergies2: "",
    notes: ""
  };


  const [rsvpDataList, setRSVPDataList] = useState<RSVPData[]>([]);
  const [rsvpData, setRSVPData] = useState<RSVPData | null>(null);
  const [modifiedRSVPData, setModifiedRSVPData] = useState<RSVPData | null>(null);
  const [queryParameters] = useSearchParams();
  const [searchParam, setSearchParam] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [stage, setStage] = useState<number>(stages.SEARCH);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      queryParameters.has("id") &&
      queryParameters.get("id") !== "" &&
      rsvpData === null
    ) {
      setStage(stages.VIEW);
      fetchRSVP(queryParameters.get("id") || "");
    }
  }, [queryParameters]); // Dependency array

  const localhost = "http://localhost:8000";
  const vercel = "https://wedding-server-flame.vercel.app";
  // const url = localhost;
  const url = vercel;

  let rsvpPageData: rsvpPageInterface;
  const language = localStorage.getItem("lang") || "en";
  if (language === "fr") {
    rsvpPageData = rsvpPage.fr;
  } else if (language === "cn") {
    rsvpPageData = rsvpPage.cn;
  } else {
    rsvpPageData = rsvpPage.en;
  }
  
  const setRsvpDataToModifiedRsvpData = () => {
    if (modifiedRSVPData && rsvpData) {
      const updatedRSVPData = { ...rsvpData };
      updatedRSVPData.firstname = modifiedRSVPData.firstname || rsvpData.firstname;
      updatedRSVPData.lastname = modifiedRSVPData.lastname || rsvpData.lastname;
      updatedRSVPData.plusonefirstname = modifiedRSVPData.plusonefirstname || rsvpData.plusonefirstname;
      updatedRSVPData.plusonelastname = modifiedRSVPData.plusonelastname || rsvpData.plusonelastname;
      updatedRSVPData.email1 = modifiedRSVPData.email1 || rsvpData.email1;
      updatedRSVPData.hiddenemail1 = modifiedRSVPData.hiddenemail1 || rsvpData.hiddenemail1;
      updatedRSVPData.email2 = modifiedRSVPData.email2 || rsvpData.email2;
      updatedRSVPData.hiddenemail2 = modifiedRSVPData.hiddenemail2 || rsvpData.hiddenemail2;
      setRSVPData(updatedRSVPData);
    }
  }


  const fetchRSVP = async (param: string) => {
    try {
      setLoading(true);
      // check if param string is number or not
      let response;
      if (/^\d+$/.test(param)) {
        response = await fetch(`${url}/rsvp/${param}`);
      } else {
        response = await fetch(`${url}/rsvp?name=${param}`);
      }

      const responseData = await response.json();
      if (response.status === 404) {
        throw new Error(
          "Oops! We were unable to find your invitation. If you are having trouble, please contact Caroline or Stephane!"
        );
      } else if (!response.ok) {
        throw new Error( // show the error message returned from the server
          `An error occurred at ${new Date().toLocaleString()} ${responseData.error}`
        );
      }
      // add timeout to simulate loading
      // setTimeout(() => {
        if (Array.isArray(responseData)) {
          setRSVPDataList(responseData);
          setRSVPData(null);
          setStage(stages.LIST);
        } else {
          setRSVPData(responseData);
          // add hidden email to rsvpData
          const updatedRSVPData = { ...responseData };
          updatedRSVPData.hiddenemail1 = updatedRSVPData.email1.replace(/^(.)(.*)(?=@)/, (match: any, p1: string, p2: string | any[]) => p1 + "*".repeat(p2.length));
          updatedRSVPData.hiddenemail2 = updatedRSVPData.email2.replace(/^(.)(.*)(?=@)/, (match: any, p1: string, p2: string | any[]) => p1 + "*".repeat(p2.length));
          
          setRSVPData(updatedRSVPData);
          setModifiedRSVPData(dummyData);

          queryParameters.set("id", responseData.rsvpid.toString());
          navigate("/?" + queryParameters.toString(), { replace: true });
          setStage(stages.VIEW);
        }
        setErrorMsg("");
        setLoading(false);
      // }, 3000);
    } catch (error: any) {
      console.error(error);
      setRSVPDataList([]);
      setRSVPData(null);
      setErrorMsg(
        error.message || `An error occurred ${new Date().toLocaleString()}`
      );
      setLoading(false);
    }
  };

  const putRSVP = async (rsvp: RSVPData, nextStage: number) => {
    try {
      setLoading(true);
      if (rsvpData?.venue1 === "no") {
        rsvpData.meal1 = "";
        rsvpData.allergies1 = "";
      } else if (rsvpData?.venue2 === "no") {
        rsvpData.meal2 = "";
        rsvpData.allergies2 = "";
      }

      const response = await fetch(`${url}/rsvp`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rsvp),
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(
          `An error occurred at ${new Date().toLocaleString()} ${responseData.error}`
        );
      }
      // setTimeout(() => {
        setErrorMsg("");
        setStage(nextStage);
        setLoading(false);
      // }, 3000);

    } catch (error: any) {
      console.error(error);
      setRSVPData(null);
      setErrorMsg(
        error.message || `An error occurred ${new Date().toLocaleString()}`
      );
      setLoading(false);
    }
  };

  const sendEmail = async (rsvp: RSVPData) => {
    try {
      setLoading(true);
      const response = await fetch(`${url}/rsvp/${rsvp.rsvpid}/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rsvp),
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(
          `An error occurred at ${new Date().toLocaleString()} ${responseData.error}`
        );
      }
      setErrorMsg("");
      setLoading(false);
      setStage(stages.EMAIL_SENT);
    } catch (error: any) {
      console.error(error);
      setRSVPData(null);
      setErrorMsg(
        error.message || `An error occurred ${new Date().toLocaleString()}`
      );
      setLoading(false);
    }
  };

  const backToSearchByName = () => {
    setRSVPDataList([]);
    setRSVPData(null);
    setSearchParam("");
    setErrorMsg("");
    setStage(stages.SEARCH);
  };

  const backToList = () => {
    setRSVPData(null);
    queryParameters.delete("id");
    navigate("/?" + queryParameters.toString(), { replace: true });
    if (rsvpDataList.length === 0) {
      setStage(stages.SEARCH);
    } else {
      setStage(stages.LIST);
    }
  };
  
  const modifyRSVP = (key: string, value: string) => {
      if (modifiedRSVPData && modifiedRSVPData.hasOwnProperty(key)) {
        const updatedRSVPData = { ...modifiedRSVPData };
        updatedRSVPData[key] = value;
        updatedRSVPData.hiddenemail1 = updatedRSVPData.email1.replace(/^(.)(.*)(?=@)/, (match: any, p1: string, p2: string | any[]) => p1 + "*".repeat(p2.length));
        updatedRSVPData.hiddenemail2 = updatedRSVPData.email2.replace(/^(.)(.*)(?=@)/, (match: any, p1: string, p2: string | any[]) => p1 + "*".repeat(p2.length));
        setModifiedRSVPData(updatedRSVPData);
      }
  };

  const modifyRsvpData = (key: string, value: string) => {
    if (rsvpData) {
      const updatedRSVPData = { ...rsvpData };
      updatedRSVPData[key] = value;
      setRSVPData(updatedRSVPData);
    }
  }

  const getIPAddress = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();

    // get country and city of the ip address
    const response2 = await fetch(`https://ipapi.co/${data.ip}/json/`);
    const data2 = await response2.json();

    // send the ip address to the server
    await fetch(`${url}/ip`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ip: data2 }),
    });
  };

  useEffect(() => {
    getIPAddress();
  }, []);

//   useEffect(() => {
//     console.log("rsvpData", rsvpData);
// }, [rsvpData])
  

  return (
    <div className="Background">
      <div className="RSVP">
        <h3>{rsvpPageData.rsvp}</h3>
        {stage === stages.SEARCH && (
          <div>
            <p>{rsvpPageData.pleaseEnterYourName}</p>
            <input
              type="text"
              placeholder={rsvpPageData.enterYourName}
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
            />
            {loading && (
              <Row style={{paddingTop: "5px"}}>
                <Box sx={{ width: "50%" }}>
                  <LinearProgress />
                </Box>
              </Row>
            )}
            <button
              className="RSVPSearch"
              onClick={() => fetchRSVP(searchParam)}
              disabled={searchParam === "" || searchParam.length <= 3 || loading}
            >
              <span>{rsvpPageData.findMyInvitation}</span>
            </button>
          </div>
        )}
        {stage === stages.LIST && (
          <div>
            <p>
              {rsvpPageData.listMessage}
            </p>
            <ButtonGroup vertical>
              {rsvpDataList.map((rsvp) => (
                <>
                  <Row key={rsvp.rsvpid}>
                    <button
                      // radio button only one can be selected
                      className="RSVPResult"
                      onClick={() => fetchRSVP(rsvp.rsvpid.toString())}
                    >
                      {rsvp.firstname} {rsvp.lastname}{" "}
                      {rsvp.plusonefirstname && <span>&</span>}{" "}
                      {rsvp.plusonefirstname} {rsvp.plusonelastname}
                    </button>
                  </Row>
                </>
              ))}
              {loading && (
                <Row style={{paddingTop: "10px"}}>
                  <Box sx={{ width: "50%" }}>
                    <LinearProgress />
                  </Box>
                </Row>
              )}
            </ButtonGroup>
            <button
              className="RSVPSearch"
              onClick={() => backToSearchByName()}
              disabled={searchParam === "" || loading}
            >
              <span>{rsvpPageData.back}</span>
            </button>
          </div>
        )}
        {stage === stages.VIEW && rsvpData === null && loading && <CircularProgress />}
        {stage === stages.VIEW && rsvpData && (
          <div className="view">
            <div className="row">
              <div className="column-left">
                <p>{rsvpPageData.name}:</p>
              </div>
              <div className="column-right">
                <p>
                  {rsvpData.firstname} {rsvpData.lastname}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column-left">
                <p>{rsvpPageData.email}:</p>
              </div>
              <div className="column-right">
                <p>
                  {rsvpData.hiddenemail1}
                </p>
              </div>
            </div>
            {rsvpData.plusone.toLowerCase() === "yes" && (
              <div>
                <div className="row">
              <div className="column-left">
                <p>{rsvpPageData.name}:</p>
              </div>
              <div className="column-right">
                <p>
                  {rsvpData.plusonefirstname} {rsvpData.plusonelastname}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column-left">
                <p>{rsvpPageData.email}:</p>
              </div>
              <div className="column-right">
                <p>
                  {rsvpData.hiddenemail2}
                </p>
              </div>
            </div>
              </div>
            )}
            {loading && (
              <Row style={{paddingTop: "5px"}}>
                <Box sx={{ width: "50%" }}>
                  <LinearProgress />
                </Box>
              </Row>
            )}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              { 
              <button
              className="ButtonRsvp"
              onClick={() => backToList()}
              disabled={loading}
              >
                <span>{rsvpPageData.back}</span>
              </button>
                }
              <button className="ButtonRsvp" disabled={loading} onClick={() => setStage(stages.EDIT)}>
                <span>{rsvpPageData.modifyNameAndEmail}</span>
              </button>
              <button className="ButtonRsvp" disabled={loading || (!rsvpData.email1 )} onClick={() => setStage(stages.CONFIRM_CHURCH)}>
                <span>{rsvpPageData.continue}</span>
              </button>
            </Box>
              {(!rsvpData.email1) && 
              <p>{rsvpPageData.emailRequired}</p>
              }
            

          </div>
        )}
        {stage === stages.EDIT && (
          <div className="edit">
            <p>{rsvpPageData.modifyMessage}</p>
            <div className="row">
              <div className="column-left">
                <label htmlFor="firstname">{rsvpPageData.firstName}</label>
              </div>
              <div className="column-right">
                <input
                  style={{ alignContent: "center"}}
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder={rsvpData?.firstname}
                  value={modifiedRSVPData?.firstname}
                  onChange={(e) => {
                    modifyRSVP("firstname", e.target.value);
                  }}
                />
              </div>
            </div>
                  
              
            <div className="row">
              <div className="column-left">
                <label htmlFor="lastname">{rsvpPageData.lastName}</label>
              </div>
              <div className="column-right">
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder={rsvpData?.lastname}
                  value={modifiedRSVPData?.lastname}
                  onChange={(e) => {
                    modifyRSVP("lastname", e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column-left">
                <label htmlFor="email1">{rsvpPageData.email}</label>
              </div>
              <div className="column-right">
                <input
                  type="text"
                  id="email1"
                  name="email1"
                  placeholder={rsvpData?.hiddenemail1}
                  value={modifiedRSVPData?.email1}
                  onChange={(e) => {
                    modifyRSVP("email1", e.target.value);
                  }}
                />
              </div>
            </div>
            {rsvpData?.plusone.toLowerCase() === "yes" && (
              <div>
                <div className="row">
                  <div className="column-left">
                    <label htmlFor="plusonefirstname">{rsvpPageData.plusOneFirstName}</label>
                  </div>
                  <div className="column-right">
                    <input
                      type="text"
                      id="plusonefirstname"
                      name="plusonefirstname"
                      placeholder={rsvpData?.plusonefirstname}
                      value={modifiedRSVPData?.plusonefirstname}
                      onChange={(e) => {
                        modifyRSVP("plusonefirstname", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column-left">
                    <label htmlFor="plusonelastname">{rsvpPageData.plusOneLastName}</label>
                  </div>
                  <div className="column-right">
                    <input
                      type="text"
                      id="plusonelastname"
                      name="plusonelastname"
                      placeholder={rsvpData?.plusonelastname}
                      value={modifiedRSVPData?.plusonelastname}
                      onChange={(e) => {
                        modifyRSVP("plusonelastname", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column-left">
                    <label htmlFor="email2">{rsvpPageData.plusOneEmail}</label>
                  </div>
                  <div className="column-right">
                    <input
                      type="text"
                      id="email2"
                      name="email2"
                      placeholder={rsvpData?.hiddenemail2}
                      value={modifiedRSVPData?.email2}
                      onChange={(e) => {
                        modifyRSVP("email2", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            
            <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
              <button className="ButtonRsvp" onClick={() => setStage(stages.VIEW)}>
                <span>{rsvpPageData.back}</span>
              </button>
              <button className="ButtonRsvp" onClick={() => {
                setRsvpDataToModifiedRsvpData();
                putRSVP(rsvpData as RSVPData, stages.VIEW);
                }}>
                <span>{rsvpPageData.confirmChanges}</span>
              </button>
            </Box>
            {loading && <CircularProgress />}

          </div>
          )}


          {stage === stages.CONFIRM_CHURCH && (
            <div>
              <div>
                <p>{rsvpPageData.replyBy}</p>
                <p>{rsvpPageData.will} <b><u>{rsvpData?.firstname} {rsvpData?.lastname}</u></b> {rsvpPageData.beAttendingChurch}</p>
                <div>
                  <input
                    type="radio"
                    id="church1-yes"
                    name="church1"
                    value="yes"
                    checked={rsvpData ? rsvpData.church1 === "yes" : false}
                    onChange={() => {
                      modifyRsvpData("church1", "yes");
                    }}
                  />
                  <label htmlFor="church1-yes">{rsvpPageData.yes}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="church1-no"
                    name="church1"
                    value="no"
                    checked={rsvpData ? rsvpData.church1 === "no" : false}
                    onChange={() => {
                      modifyRsvpData("church1", "no");
                    }}
                  />
                  <label htmlFor="church1-no">{rsvpPageData.no}</label>
                </div>
                {rsvpData?.plusone.toLowerCase() === "yes" && (
                  <div>
                    <p>{rsvpPageData.will} <b><u>{rsvpData?.plusonefirstname} {rsvpData?.plusonelastname}</u></b> {rsvpPageData.beAttendingChurch}</p>
                    <div>
                      <input
                        type="radio"
                        id="church2-yes"
                        name="church2"
                        value="yes"
                        checked={rsvpData ? rsvpData.church2 === "yes" : false}
                        onChange={() => {
                          modifyRsvpData("church2", "yes");
                        }}
                      />
                      <label htmlFor="church2-yes">{rsvpPageData.yes}</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="church2-no"
                        name="church2"
                        value="no"
                        checked={rsvpData ? rsvpData.church2 === "no" : false}
                        onChange={() => {
                          modifyRsvpData("church2", "no");
                        }}
                      />
                      <label htmlFor="church2-no">{rsvpPageData.no}</label>
                    </div>
                  </div>
                )}

                <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                  <button className="ButtonRsvp" onClick={() => setStage(stages.VIEW)}>
                    <span>{rsvpPageData.back}</span>
                  </button>
                  <button className="ButtonRsvp" onClick={() => setStage(stages.CONFIRM_VENUE)}
                  disabled={rsvpData?.church1 === "" || (rsvpData?.plusone.toLowerCase() === "yes" && rsvpData?.church2 === "")}
                  >
                    <span>{rsvpPageData.continue}</span>
                  </button>
                </Box>
              </div>
            </div>
          )}
          {stage === stages.CONFIRM_VENUE && (
            <div>
              <div>
                <p>{rsvpPageData.replyBy}</p>
                <p>{rsvpPageData.will} <b><u>{rsvpData?.firstname} {rsvpData?.lastname}</u></b> {rsvpPageData.beAttendingWedding}</p>
                <div>
                  <input
                    type="radio"
                    id="venue1-yes"
                    name="venue1"
                    value="yes"
                    checked={rsvpData ? rsvpData.venue1 === "yes" : false}
                    onChange={() => {
                      modifyRsvpData("venue1", "yes");
                    }}
                  />
                  <label htmlFor="venue1-yes">{rsvpPageData.yes}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="venue1-no"
                    name="venue1"
                    value="no"
                    checked={rsvpData ? rsvpData.venue1 === "no" : false}
                    onChange={() => {
                      modifyRsvpData("venue1", "no");
                    }}
                  />
                  <label htmlFor="venue1-no">{rsvpPageData.no}</label>
                </div>
                {rsvpData?.plusone.toLowerCase() === "yes" && (
                  <div>
                    <p>{rsvpPageData.will} <b><u>{rsvpData?.plusonefirstname} {rsvpData?.plusonelastname}</u></b> {rsvpPageData.beAttendingWedding}</p>
                    <div>
                      <input
                        type="radio"
                        id="venue2-yes"
                        name="venue2"
                        value="yes"
                        checked={rsvpData ? rsvpData.venue2 === "yes" : false}
                        onChange={() => {
                          modifyRsvpData("venue2", "yes");
                        }}
                      />
                      <label htmlFor="venue2-yes">{rsvpPageData.yes}</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="venue2-no"
                        name="venue2"
                        value="no"
                        checked={rsvpData ? rsvpData.venue2 === "no" : false}
                        onChange={() => {
                          modifyRsvpData("venue2", "no");
                        }}
                      />
                      <label htmlFor="venue2-no">{rsvpPageData.no}</label>
                    </div>
                  </div>
                )}

                <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                  <button className="ButtonRsvp" onClick={() => setStage(stages.CONFIRM_CHURCH)}>
                    <span>{rsvpPageData.back}</span>
                  </button>
                  <button className="ButtonRsvp" onClick={() => {
                    if (rsvpData?.venue1 === "yes" || rsvpData?.venue2 === "yes") {
                      setStage(stages.CONFIRM_MEAL)
                    } else {
                      putRSVP(rsvpData as RSVPData, stages.SEND_EMAIL);
                    }
                    }}
                    disabled={rsvpData?.venue1 === "" || (rsvpData?.plusone.toLowerCase() === "yes" && rsvpData?.venue2 === "")}
                    >
                    <span>{rsvpPageData.continue}</span>
                  </button>
                </Box>
                {loading && <CircularProgress />}
              </div>
            </div>
          )}

          {stage === stages.CONFIRM_MEAL && (
            <div className="meal">
              <p>{rsvpPageData.replyBy}</p>
              {/* <p>{rsvpPageData.menuChoice}</p> */}
              {rsvpData?.venue1.toLowerCase() === "yes" && (
                <>
                
              <p>{rsvpPageData.dinnerMenuFor} <b><u>{rsvpData?.firstname} {rsvpData?.lastname}</u></b>?</p>
                <div>
                  <input
                    type="radio"
                    id="meal1-pescatarian"
                    name="meal1"
                    value="pescatarian"
                    checked={rsvpData ? rsvpData.meal1 === "pescatarian" : false}
                    onChange={() => {
                      modifyRsvpData("meal1", "pescatarian");
                    }}
                  />
                  <label htmlFor="meal1-pescatarian">{rsvpPageData.pescatarian}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="meal1-filet mignon"
                    name="meal1"
                    value="filet mignon"
                    checked={rsvpData ? rsvpData.meal1 === "filet mignon" : false}
                    onChange={() => {
                      modifyRsvpData("meal1", "filet mignon");
                    }}
                  />
                  <label htmlFor="meal1-filet mignon">{rsvpPageData.filetMignon}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="meal1-vegetarian"
                    name="meal1"
                    value="vegetarian"
                    checked={rsvpData ? rsvpData.meal1 === "vegetarian" : false}
                    onChange={() => {
                      modifyRsvpData("meal1", "vegetarian");
                    }}
                  />
                  <label htmlFor="meal1-vegetarian">{rsvpPageData.vegetarian}</label>
                </div>
                <div>
                  <label htmlFor="allergies1">{rsvpPageData.anyAllergies}<br/>{rsvpPageData.beSpecific}</label><br />
                  <input
                    type="text"
                    id="allergies1"
                    name="allergies1"
                    value={rsvpData ? rsvpData.allergies1 : ""}
                    onChange={(e) => {
                      modifyRsvpData("allergies1", e.target.value);
                    }}
                  />
                </div>
                </>
              )}

              {rsvpData?.plusone.toLowerCase() === "yes" && rsvpData?.venue2.toLowerCase() === "yes" && (
                <div><br />
                  <p>{rsvpPageData.dinnerMenuFor} <b><u>{rsvpData?.plusonefirstname} {rsvpData?.plusonelastname}</u></b>?</p>
                <div>
                  <input
                    type="radio"
                    id="meal2-pescatarian"
                    name="meal2"
                    value="pescatarian"
                    checked={rsvpData ? rsvpData.meal2 === "pescatarian" : false}
                    onChange={() => {
                      modifyRsvpData("meal2", "pescatarian");
                    }}
                  />
                  <label htmlFor="meal2-pescatarian">{rsvpPageData.pescatarian}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="meal2-filet mignon"
                    name="meal2"
                    value="filet mignon"
                    checked={rsvpData ? rsvpData.meal2 === "filet mignon" : false}
                    onChange={() => {
                      modifyRsvpData("meal2", "filet mignon");
                    }}
                  />
                  <label htmlFor="meal2-filet mignon">{rsvpPageData.filetMignon}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="meal2-vegetarian"
                    name="meal2"
                    value="vegetarian"
                    checked={rsvpData ? rsvpData.meal2 === "vegetarian" : false}
                    onChange={() => {
                      modifyRsvpData("meal2", "vegetarian");
                    }}
                  />
                  <label htmlFor="meal2-vegetarian">{rsvpPageData.vegetarian}</label>
                </div>
                <div>
                  <label htmlFor="allergies2">{rsvpPageData.anyAllergies}<br/>{rsvpPageData.beSpecific}</label><br />
                  <input
                    type="text"
                    id="allergies2"
                    name="allergies2"
                    value={rsvpData ? rsvpData.allergies2 : ""}
                    onChange={(e) => {
                      modifyRsvpData("allergies2", e.target.value);
                    }}
                  />
                </div>
                </div>

              )}
              <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                <button className="ButtonRsvp" onClick={() => setStage(stages.CONFIRM_VENUE)}>
                  <span>{rsvpPageData.back}</span>
                </button>
                <button className="ButtonRsvp" onClick={() => {
                  setStage(stages.NOTES)
                  
                  }}
                  disabled={(rsvpData?.venue1.toLowerCase() === "yes" && rsvpData?.meal1 === "") || (rsvpData?.plusone.toLowerCase() === "yes" && rsvpData?.venue2.toLowerCase() === "yes" && rsvpData?.meal2 === "")}>
                  <span>{rsvpPageData.continue}</span>
                </button>
              </Box>
            </div>
          )}

          {stage === stages.NOTES && (
            <div>
              <div>
                <label htmlFor="notes">{rsvpPageData.notesToCouple} </label>
              </div>
                <textarea
                  id="notes"
                  name="notes"
                  value={rsvpData ? rsvpData.notes : ""}
                  onChange={(e) => {
                    modifyRsvpData("notes", e.target.value);
                  }}
                />
              {/* {loading && (
              <Row style={{paddingTop: "5px"}}>
                <Box sx={{ width: "50%" }}>
                  <LinearProgress />
                </Box>
              </Row>
            )} */}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <button className="ButtonRsvp" onClick={() => setStage(stages.CONFIRM_MEAL)}>
                  <span>{rsvpPageData.back}</span>
                </button>
                <button className="ButtonRsvp" onClick={() => {
                  putRSVP(rsvpData as RSVPData, stages.SEND_EMAIL);
                  }}>
                  <span>{rsvpPageData.continue}</span>
                </button>
              </Box>
              {loading && <CircularProgress />}
            </div>
          )}

          {stage === stages.SEND_EMAIL && (
            <div>
              <p>{rsvpPageData.thankYou}</p>
              <p>{rsvpPageData.weLookForward}</p>
              {loading && (
              <Row style={{paddingTop: "5px"}}>
                <div style={{width: "clamp(100px, 50%, 400px)"}}>
                  <LinearProgress />
                </div>
              </Row>
            )}
              <Box sx={{ display: "flex", justifyContent: "center"}}>
                <button className="ButtonRsvp" onClick={() => {
                    sendEmail(rsvpData as RSVPData);
                  
                  }}>
                  <span>{rsvpPageData.sendConfirmation}</span>
                </button>
              </Box>
            </div>
          )}
          {stage === stages.EMAIL_SENT && (
            <div>
              <p>{rsvpPageData.confirmationSent}</p>
              <p>{rsvpPageData.thankYou}</p>
              <Box sx={{ display: "flex", justifyContent: "center"}}>
                <button className="ButtonRsvp" onClick={() => {
                  changeSection(0);
                }}>
                  <span>{rsvpPageData.home}</span>
                </button>
              </Box>
            </div>

          )}
        {errorMsg && 
          <>
            <p>{errorMsg}</p>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                { 
                <button
                className="ButtonRsvp"
                onClick={() => backToSearchByName()}
                disabled={loading}
                >
                  <span>{rsvpPageData.back}</span>
                </button>
                  }
              </Box> 
          </>
        }
      </div>
    </div>
  );
};

export default RSVP;
