import { motion } from "framer-motion";
import { schedule, scheduleInterface } from "./constants";

const Schedule = () => {
    const defaultLanguage = localStorage.getItem("lang") || "en";
    let scheduleData: scheduleInterface[];
    if (defaultLanguage === "fr") {
        scheduleData = schedule.fr;
    } else if (defaultLanguage === "cn") {
        scheduleData = schedule.cn;
    } else {
        scheduleData = schedule.en;
    }



      return (
    <div>
        <div className="schedule">
          {scheduleData.map((s, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: false }}
                transition={{ duration: 0.5 }}
                className="row"
              >
                <div className="column-left">
                  <p key={index}>
                    <strong>{s.time}</strong>
                  </p>
                </div>
                <div className="column-right">
                  <p key={index}>
                    <b>{s.event}</b>
                    <br />
                    {s.description}
                    <br />
                    <a style={{color:'#666', textDecorationColor:'none'}} href={s.mapsLink} target="_blank" rel="noreferrer">
                      {s.address}
                    </a>
                  </p>
                </div>
              </motion.div>
            ))}
        </div>
            </div>
      );
}

export default Schedule;
