import { useLocation } from "react-router-dom";
import Homepage from "./Homepage";
import "./App.css";

function App() {
  const location = useLocation();
  const path = location.pathname;

  if (path === "/rsvp") {
    localStorage.setItem("section", "4");
  }
  return (<Homepage />);
}

export default App;
