export interface sectionsInterface {
    home: string;
    // photos: string;
    schedule: string;
    qa: string;
    rsvp: string;
}
export const sections = {
  en: {
    home: "Home",
    // photos: "Photos",
    schedule: "Schedule",
    qa: "Q&A",
    rsvp: "RSVP",
  },
  fr: {
    home: "Accueil",
    // photos: "Photos",
    schedule: "Horaire",
    qa: "Q&R",
    rsvp: "RSVP",
  },
  cn: {
    home: "主页",
    // photos: "照片",
    schedule: "时间表",
    qa: "问答",
    rsvp: "RSVP",
  },
};

export interface qaInterface {
    question: string;
    answer: string;
    answer2?: string;
    answer3?: string;
    answer4?: string;
    }

export const questionsAndAnswers = {
  en: [
    {
      question: "HOW DO I RSVP?",
      answer:
        "Please RSVP online through our website header by clicking the 'RSVP' tab and filling out the form through to the end for submission.",
    },
    {
      question: "IS THERE A DRESS CODE FOR THE BIG DAY?",
      answer:
        "We'd love for you to dress up for the occasion to celebrate with us!",
      answer2:
        "For the church ceremony, we ask that you arrive in a semi-formal attire meaning dress shirts, suits, summer dress & cocktail dress (no jeans, no flip flops, no tank top).",
      answer3:
        "For the dinner reception, we ask that you arrive in formal attire meaning ties/bowties and evening gowns."
    },
    {
      question: "ARE KIDS WELCOME?",
      answer:
        "As much as we love your little ones, we have decided to slim down the number of kids and limit it to family only. Thank you for your understanding! We hope this advance notice means you'll be able to let your hair down and celebrate with us on the day!",
    },
    {
      question: "CAN I BRING A PLUS ONE?",
      answer:
        "Unfortunately, we can only accommodate guests if they are listed amongst your party group.",
    },
    {
      question: "IS THERE AN OPEN BAR?",
      answer:
        "Yes there is! Drinks will be available throughout the whole evening starting cocktail hour at 17h00. Therefore, we suggest using UBER as transportation means to enjoy your drinks responsably.",
    },
    {
      question: "WHERE ARE THE CEREMONY AND RECEPTION TAKING PLACE?",
      answer:
        "The ceremony is taking place at Église catholique Saint-Enfant-Jésus on 5039 Saint Dominique St., Montreal, Quebec H2T 1V1.",
      answer2:
        "The reception is taking place at Le Château Royal on 3500 Bd du Souvenir, Laval, QC H7V 1X2."
    },
    {
      question: "HOW DOES THE PARKING WORK?",
      answer: "Church Ceremony:",
      answer2:
        "There is complementary, but limited parking space behind the church. We suggest you arrive earlier to benefit from it. If upon arrival, the parking space is full, there are several other parking spots in the streets.",
      answer3: "Cocktail & Dinner Reception:",
      answer4: "We suggest you arrive earlier to benefit from the complementary but limited parking space at the venue."

    },
  ],
  fr: [
    {
      question: "COMMENT PUIS-JE CONFIRMER MA PRÉSENCE?",
      answer:
        "Veuillez confirmer votre présence en ligne via notre site Web en cliquant sur l'onglet 'RSVP' et en remplissant le formulaire jusqu'à la fin pour soumission.",
    },
    {
      question: "Y'A-T-IL UN CODE VESTIMENTAIRE POUR LE GRAND JOUR?",
      answer:
        "Nous aimerions que vous vous habilliez pour l'occasion pour célébrer avec nous!",
      answer2:
        "Pour la cérémonie à l'église, nous vous demandons d'arriver en tenue semi-formelle, c'est-à-dire chemises habillées, costumes, robes d'été et robes de cocktail (pas de jeans, pas de tongs, pas de débardeur).",
      answer3:
        "Pour le souper de réception, nous vous demandons d'arriver en tenue formelle, c'est-à-dire cravates/nœuds papillon et robes de soirée."
    },
    {
      question: "LES ENFANTS SONT-ILS LES BIENVENUS?",
      answer:
        "Autant que nous aimons vos petits, nous avons décidé de réduire le nombre d'enfants et de le limiter à la famille uniquement. Merci pour votre compréhension! Nous espérons que cet avis préalable signifie que vous pourrez vous détendre et célébrer avec nous le jour!",
    },
    {
      question: "PUIS-JE APPORTER UN PLUS UN?",
      answer:
        "Malheureusement, nous ne pouvons accueillir que les invités qui sont répertoriés parmi votre groupe de fête.",
    },
    {
      question: "Y'A-T-IL UN BAR OUVERT?",
      answer:
        "Oui il y en a un! Les boissons seront disponibles tout au long de la soirée à partir de l'heure du cocktail à 17h00. Par conséquent, nous vous suggérons d'utiliser UBER comme moyen de transport pour profiter de vos boissons de manière responsable.",
    },
    {
      question: "OÙ SE DÉROULENT LA CÉRÉMONIE ET LA RÉCEPTION?",
      answer:
        "La cérémonie a lieu à l'Église catholique Saint-Enfant-Jésus au 5039 Saint Dominique St., Montréal, Québec H2T 1V1.",
      answer2:
        "La réception a lieu au Château Royal au 3500 Bd du Souvenir, Laval, QC H7V 1X2."
    },
    {
      question: "COMMENT FONCTIONNE LE STATIONNEMENT?",
        answer:
          "Cérémonie à l'église:",
        answer2:
          "Il y a un espace de stationnement gratuit mais limité derrière l'église. Nous vous suggérons d'arriver plus tôt pour en bénéficier. Si à votre arrivée, l'espace de stationnement est plein, il y a plusieurs autres places de stationnement dans les rues.",
        answer3: "Cocktail & Souper de réception:",
        answer4: "Nous vous suggérons d'arriver plus tôt pour bénéficier de l'espace de stationnement gratuit mais limité sur place."
    },
  ],
  cn: [
    {
        question: "我如何确认出席?",
        answer:
            "请通过我们的网站标题在线确认出席，点击'RSVP'选项卡并填写表格直到提交。",
    },
    {
        question: "大日子有着装要求吗?",
        answer:
            "我们希望您为这个特殊场合穿得漂亮来与我们一起庆祝!",
        answer2:
            "对于教堂仪式，我们要求您穿着半正式服装，即衬衫、西装、夏季连衣裙和鸡尾酒裙（不要穿牛仔裤、拖鞋、背心）。",
        answer3:
            "对于晚宴招待会，我们要求您穿着正式服装，即领带/领结和晚礼服。"
    },
    {
        question: "欢迎孩子吗?",
        answer:
            "尽管我们非常喜欢您的小朋友，但我们决定减少孩子的数量并仅限于家人。感谢您的理解! 我们希望提前通知您这意味着您将能够放松并在当天与我们一起庆祝!",
    },
    {
        question: "我可以带一个加一吗?",
        answer:
            "很抱歉，我们只能接待在您的派对团体中列出的客人。",
    },
    {
        question: "有开放式酒吧吗?",
        answer:
            "是的! 饮料将在整个晚上提供，从17h00的鸡尾酒时间开始。因此，我们建议您使用UBER作为交通工具，以负责任地享用您的饮料。",
    },
    {
        question: "仪式和招待会在哪里举行?",
        answer:
            "教堂仪式在蒙特利尔圣多米尼克街5039号的Église catholique Saint-Enfant-Jésus举行。",
        answer2:
            "招待会在拉瓦尔3500 Bd du Souvenir的Le Château Royal举行。"
    },
    {
        question: "停车如何运作?",
        answer: "教堂仪式:",
        answer2:
            "教堂后面有免费但有限的停车位。我们建议您提前到达以获益。如果到达时停车位已满，街道上还有其他几个停车位。",
        answer3: "鸡尾酒和晚宴招待会:",
        answer4: "我们建议您提前到达以获益于现场的免费但有限的停车位。"
    },
  ],
};

export interface scheduleInterface {
    time: string;
    event: string;
    description: string;
    address: string;
    mapsLink: string;
}

export const schedule = {
    en: [
        {
            time: "13h30",
            event: "Church Ceremony",
            description: "Église Catholique Saint-Enfant-Jésus",
            address: "5039 Saint-Dominique, Montreal, QC H2T 1V1",
            mapsLink: "https://maps.app.goo.gl/cze5XkXGkn8wmetB8"

        },
        {
            time: "17h00",
            event: "Cocktail & Dinner Reception",
            description: "Le Château Royal",
            address: "3500 Boulevard du Souvenir, Laval, QC H7V 1X2",
            mapsLink: "https://maps.app.goo.gl/DvrTP7LPSALkdcfZ9"
        },
    ],
    fr: [
        {
            time: "13h30",
            event: "Cérémonie à l'église",
            description: "Église catholique Saint-Enfant-Jésus",
            address: "5039 Saint-Dominique St. Montreal, QC H2T 1V1",
            mapsLink: "https://maps.app.goo.gl/cze5XkXGkn8wmetB8"
        },
        {
            time: "17h00",
            event: "Cocktail & souper de réception",
            description: "Le Château Royal",
            address: "3500 Boulevard du Souvenir, Laval, QC H7V 1X2",
            mapsLink: "https://maps.app.goo.gl/DvrTP7LPSALkdcfZ9"
        }
    ],
    cn: [
        {
            time: "13h30",
            event: "教堂仪式",
            description: "Église Catholique Saint-Enfant-Jésus",
            address: "5039 Saint-Dominique St. Montreal, QC H2T 1V1",
            mapsLink: "https://maps.app.goo.gl/cze5XkXGkn8wmetB8"
        },
        {
            time: "17h00",
            event: "鸡尾酒 & 晚宴招待会",
            description: "Le Château Royal",
            address: "3500 Boulevard du Souvenir, Laval, QC H7V 1X2",
            mapsLink: "https://maps.app.goo.gl/DvrTP7LPSALkdcfZ9"
        }
    ]
}

export interface homeInfoInterface {
    title: string;
    couple: string;
    date: string;
    theirwedding?: string;
    city: string;
    daystogo: string;
    rsvp: string;
    weddingDay: string;
    attire: string;
    schedule: string;
}
export const homeInfo = {
    en: {
        title: "The wedding of",
        couple: "Stephane & Caroline",
        date: "Saturday, October 12 2024",
        city: "Montreal, QC, Canada",
        daystogo: "days to go!",
        rsvp: "RSVP",
        weddingDay: "WEDDING DAY",
        attire: "Attire: We'd love for you to dress up and celebrate with us! A semi-formal attire is encouraged for the church ceremony and a formal attire is encouraged for the reception (tie/bowtie and evening gown).",
        schedule: "Schedule"
        },
    
    fr: {
        title: "Le mariage de",
        couple: "Stéphane & Caroline",
        date: "Samedi, 12 octobre 2024",
        city: "Montréal, QC, Canada",
        daystogo: "jours avant le grand jour!",
        rsvp: "RSVP",
        weddingDay: "JOUR DU MARIAGE",
        attire: "Tenue: Nous aimerions que vous vous habilliez pour l'occasion et que vous célébriez avec nous! Une tenue semi-formelle est encouragée pour la cérémonie à l'église et une tenue formelle est encouragée pour la réception (cravate/noeud papillon et robe de soirée).",
        schedule: "Horaire"
    },
    cn: {
        title: "诚挚邀请您参加",
        couple: "Stephane & Caroline",
        date: "2024年10月12日星期六",
        theirwedding: "的婚礼",
        city: "",
        daystogo: "倒计时：",
        rsvp: "RSVP",
        weddingDay: "谨定于",
        attire: "着装: 请盛装出席, 与我们一起见证美好的时刻。",
        schedule: "时间表",
    }
}
export interface rsvpPageInterface {
    rsvp: string;
    pleaseEnterYourName: string;
    enterYourName: string;
    findMyInvitation: string;
    back: string;
    listMessage: string;
    name: string;
    email: string;
    modifyNameAndEmail: string;
    continue: string;
    emailRequired: string;
    modifyMessage: string;
    firstName: string;
    lastName: string;
    plusOneFirstName: string;
    plusOneLastName: string;
    plusOneEmail: string;
    confirmChanges: string;
    will: string;
    beAttendingChurch: string;
    beAttendingWedding: string;
    replyBy: string;
    yes: string;
    no: string;
    menuChoice: string;
    pescatarian: string;
    vegetarian: string;
    filetMignon: string;
    anyAllergies: string;
    beSpecific: string;
    dinnerMenuFor: string;
    notesToCouple: string;
    thankYou: string;
    weLookForward: string;
    sendConfirmation: string;
    confirmationSent: string;
    home: string;
}

export const rsvpPage = {
    en: {
        rsvp: "RSVP",
        pleaseEnterYourName: "Please first enter your name to find your invitation.",
        enterYourName: "Enter your name",
        findMyInvitation: "Find my invitation",
        back: "Back",
        listMessage: "We've found you in the guest list. Please confirm your name below to continue with your RSVP.",
        name: "Name",
        email: "Email",
        modifyNameAndEmail: "Modify name and email",
        continue: "Continue",
        emailRequired: "Email is required before continuing.",
        modifyMessage: "Modify your name and email.",
        firstName: "First Name",
        lastName: "Last Name",
        plusOneFirstName: "Plus One First Name",
        plusOneLastName: "Plus One Last Name",
        plusOneEmail: "Plus One Email",
        confirmChanges: "Confirm Changes",
        will: "Will",
        beAttendingChurch: "be attending the church ceremony at 13h30?",
        beAttendingWedding: "be attending the wedding reception starting at 17h00?",
        replyBy: "Kindly reply by August 31st.",
        yes: "Yes",
        no: "No",
        menuChoice: "Menu Choice",
        pescatarian: "Salmon",
        vegetarian: "Vegetarian",
        filetMignon: "Filet Mignon",
        anyAllergies: "Do you have any dietary restrictions?",
        beSpecific: "Please be specific in your answer.",
        dinnerMenuFor: "What is your meal pick for the dinner reception for",
        notesToCouple: "Notes or comments to the couple:",
        thankYou: "Thank you for your RSVP!",
        weLookForward: "We look forward to celebrating with you!",
        sendConfirmation: "Send Confirmation by Email",
        confirmationSent: "The confirmation has been sent!",
        home: "Home",
    },
    fr: {
        rsvp: "RSVP",
        pleaseEnterYourName: "Veuillez entrer votre nom pour trouver votre invitation.",
        enterYourName: "Entrez votre nom",
        findMyInvitation: "Trouvez mon invitation",
        back: "Retour",
        listMessage: "Nous vous avons trouvé dans la liste des invités. Veuillez confirmer votre nom ci-dessous pour continuer avec votre RSVP.",
        name: "Nom",
        email: "Email",
        modifyNameAndEmail: "Modifier mon information",
        continue: "Continuer",
        emailRequired: "Votre email est requis avant de continuer.",
        modifyMessage: "Modifiez votre nom et votre email.",
        firstName: "Prénom",
        lastName: "Nom de famille",
        plusOneFirstName: "Prénom du Plus Un",
        plusOneLastName: "Nom de famille du Plus Un",
        plusOneEmail: "Email du Plus Un",
        confirmChanges: "Confirmer les modifications",
        will: "Est-ce que",
        beAttendingChurch: "assistera à la cérémonie de l'église à 13h30?",
        beAttendingWedding: "assistera au mariage à partir de 17h00?",
        replyBy: "Veuillez répondre avant le 31 août.",
        yes: "Oui",
        no: "Non",
        menuChoice: "Choix de menu",
        pescatarian: "Saumon",
        vegetarian: "Végétarien",
        filetMignon: "Filet Mignon",
        anyAllergies: "Avez vous des restrictions alimentaires?",
        beSpecific: "Veuillez être spécifique dans votre réponse.",
        dinnerMenuFor: "Quel est votre choix de repas pour le souper de réception pour",
        notesToCouple: "Notes ou commentaires au couple:",
        thankYou: "Merci pour votre RSVP!",
        weLookForward: "Nous avons hâte de célébrer avec vous!",
        sendConfirmation: "Envoyer la confirmation par email",
        confirmationSent: "La confirmation a été envoyée!",
        home: "Accueil",
    },
    cn: {
        rsvp: "RSVP",
        pleaseEnterYourName: "请先输入您的姓名以找到您的邀请。",
        enterYourName: "输入您的名字",
        findMyInvitation: "找到我的邀请",
        back: "返回",
        listMessage: "我们在客人名单中找到了您。 请在下面确认您的姓名以继续RSVP。",
        name: "名称",
        email: "电子邮件",
        modifyNameAndEmail: "修改姓名和电子邮件",
        continue: "继续",
        emailRequired: "继续之前需要电子邮件。",
        modifyMessage: "修改您的姓名和电子邮件。",
        firstName: "名字",
        lastName: "姓",
        plusOneFirstName: "加一名字",
        plusOneLastName: "加一姓",
        plusOneEmail: "加一电子邮件",
        confirmChanges: "确认更改",
        will: "",
        beAttendingChurch: "会参加13点30分的教堂仪式吗?",
        beAttendingWedding: "会参加17点的婚礼吗?",
        replyBy: "请在8月31日前回复。",
        yes: "是",
        no: "不",
        menuChoice: "菜单选择",
        pescatarian: "鲑鱼",
        vegetarian: "素食",
        filetMignon: "菲力牛排",
        anyAllergies: "您有任何饮食限制吗?",
        beSpecific: "请在回答中具体说明。",
        dinnerMenuFor: "晚餐菜单",
        notesToCouple: "给夫妇的备注或评论：",
        thankYou: "感谢您的RSVP！",
        weLookForward: "我们期待与您一起庆祝！",
        sendConfirmation: "通过电子邮件发送确认",
        confirmationSent: "确认已发送！",
        home: "主页",
    }
}