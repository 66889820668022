import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled, useTheme } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  sections as SectionsAllLanguages,
  sectionsInterface,
} from "./constants";

interface Tabs2Props {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const Tabs2: React.FC<Tabs2Props> = ({ value, onChange }) => {
  interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const StyledTabs = styled((props: StyledTabsProps) => {
    return (
      <Tabs
        {...props}
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />,
        }}
        centered={!isSmallScreen}
        scrollButtons={isSmallScreen ? "auto" : false}
        variant={isSmallScreen ? "scrollable" : "standard"}
        allowScrollButtonsMobile
      />
    );
  })({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#000",
    },
  });

  interface StyledTabProps {
    label: string;
  }

  const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
  ))(({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.7)",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(0, 0, 0, 0.32)",
    },
  }));

  const language = localStorage.getItem("lang") || "en";
  let sections: sectionsInterface;
    if (language === "fr") {
        sections = SectionsAllLanguages.fr;
    } else if (language === "cn") {
        sections = SectionsAllLanguages.cn;
    } else {
        sections = SectionsAllLanguages.en;
    }

  return (
    <StyledTabs value={value} onChange={onChange}>
        {Object.values(sections).map((section, index) => (
            <StyledTab key={index} label={section} />
        ))}
    </StyledTabs>
  );
};

export default Tabs2;
